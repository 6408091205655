@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Code&family=Inter:wght@400;600;800&display=swap');

body {
  margin: 0;
  font-family: Inter;
  @apply text-gray-800;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  margin: 0;
  font-family: Inter;
  @apply text-gray-100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark .d2h-file-header {
  @apply bg-darkmode2;
  @apply border-darkmode;
}

.dark .d2h-info {
  @apply bg-darkmode2;
  @apply border-darkmode;
}

.dark .d2h-info {
  @apply text-gray-100;
  @apply border-darkmode;
}

.dark .d2h-code-wrapper {
  @apply text-gray-900;
  @apply border-darkmode;
}

.dark .d2h-cntx {
  @apply text-white;
}

.dark .d2h-code-side-linenumber {
  @apply text-white;
  @apply bg-darkmode2;
}

.dark .d2h-code-side-emptyplaceholder {
  @apply bg-darkmode2;
}

code {
  font-family: 'Fira Code',
  monospace;
  color: white;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 5px;
  background-color: theme('colors.code-block');
  font-size: .8em;
}

.dark code {
  background-color: theme('colors.darkmode2');
}

td {
  box-sizing: border-box;
}

.d2h-file-list-title {
  display: none;
}

.d2h-file-list-wrapper {
  display: none;
}

.font-mono {
  font-family: ui-monospace, 'Fira Code', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
